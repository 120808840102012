<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-list">
    <GroupSidebarForm
      :isSidebarActive="addNewDataSidebar"
      @closeSidebar="toggleDataSidebar"
      @refreshData="refreshData"
      :data="sidebarData"
    />
    <vx-card ref="filterCard" class="user-list-filters mb-8">
      <div class="flex justify-between align-items-center mb-8">
        <h2 class="mb-0">Learning Centres</h2>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col md:w-1/2 w-full">
          <vs-input
            class="w-full search-input admin-search-placholder"
            v-model="searchQuery"
            placeholder="Search..."
          />
        </div>
        <div class="vx-col md:w-1/2 w-full flex justify-end">
          <vs-button color="primary" type="filled" @click="addNewGroup"
            >Add Group</vs-button
          >
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="block mb-2">Country</label>
          <v-select
            :options="countryOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="countryFilter"
            class="mb-4 md:mb-0"
          />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="block mb-2">Region</label>
          <v-select
            :options="stateOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="stateFilter"
            class="mb-4 md:mb-0"
          />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="block mb-2">View By</label>
          <v-select
            :options="viewOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="viewByFilter"
            class="mb-4 md:mb-0"
          />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="block mb-2">Sort By</label>
          <v-select
            :options="sortByOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="sortByFilter"
            class="sort-dropdown"
          />
        </div>
      </div>
    </vx-card>

    <vx-card>
      <vs-table :data="groups" @selected="handleSelected">
        <template slot="thead">
          <vs-th>Name</vs-th>
          <vs-th>Country</vs-th>
          <vs-th>Region</vs-th>
          <vs-th>No. Centres</vs-th>
          <vs-th>Reset Password</vs-th>
          <vs-th></vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.companyName">
              {{ tr.companyName }}
            </vs-td>
            <vs-td v-if="tr.address">
              {{ tr.address.country || "N/A" }}
            </vs-td>
            <vs-td v-else>
              {{ "N/A" }}
            </vs-td>
            <vs-td v-if="tr.address">
              {{ tr.address.state || "N/A"  }}
            </vs-td>
            <vs-td v-else>
              {{  "N/A" }}
            </vs-td>
            <vs-td :data="tr._id">
              {{ tr.learningCenters.length }}
            </vs-td>
            <vs-td :data="tr._id">
              <a href="#" @click="resetPassword(tr._id)">Reset Password</a>
            </vs-td>
            <vs-td :data="tr._id">
              <a
                href="#"
                class="nav-link d-flex align-items-center active"
                @click.stop="viewGroup(tr._id)"
              >
                <feather-icon
                  icon="EyeIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current icon-eye"
                />
              </a>
              <!--              <a href="#" class="nav-link d-flex align-items-center active" @click.stop="deleteData(tr._id)">-->
              <!--                <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"/>-->
              <!--              </a>-->
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
    <scroll-loader :loader-method="getAllGroups" :loader-enable="loadMore">
      <div></div>
    </scroll-loader>
  </div>
</template>

<script>
import Vue from "vue";
import ScrollLoader from "vue-scroll-loader";
import vSelect from "vue-select";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import { mapActions } from "vuex";
import GroupSidebarForm from "./GroupSidebarForm.vue";

Vue.use(ScrollLoader);

export default {
  components: {
    vSelect,
    GroupSidebarForm
  },

  data() {
    return {
      loadMore: true,
      page: 0,
      limit: 20,
      groups: [],
      countryFilter: { label: "All", value: "all" },
      countryOptions: [],
      stateFilter: { label: "All", value: "all" },
      stateOptions: [],
      sortByFilter: { label: "Name", value: "companyName" },
      sortByOptions: [
        { label: "Name", value: "companyName" },
        { label: "Country", value: "country.name" },
        { label: "Region", value: "address.state" },
        { label: "No of center", value: "centerCount" }
      ],
      viewByFilter: { label: "Group", value: "group" },
      viewOptions: [
        { label: "Centres", value: "center" },
        { label: "Group", value: "group" }
      ],

      searchQuery: "",
      awaitingSearch: false,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {}
    };
  },
  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
      this.stateFilter = { label: "All", value: "all" };
      this.getAllGroups("sort");
    },
    stateFilter(obj) {
      this.getAllGroups("sort");
    },
    sortByFilter(obj) {
      this.getAllGroups("sort");
    },
    searchQuery: function(val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getAllGroups("sort");
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    viewByFilter(obj) {
      if (this.viewByFilter.value === "group") {
        this.$router.push({ name: "group-management" });
      }
      if (this.viewByFilter.value === "center") {
        this.$router.push({ name: "learning-centre-list" });
      }
    }
  },
  computed: {},
  methods: {
    ...mapActions("group", [
      "fetchGroups",
      "deleteGroupById",
      "getAllCountries"
    ]),
    ...mapActions("center", ["resetUserPassword"]),
    handleSelected(tr) {
      this.viewGroup(tr._id);
    },
    resetPassword(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want reset password for this user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!"
      }).then(result => {
        if (result.value) {
          this.acceptPasswordResetAlert(id);
        }
      });
    },
    async acceptPasswordResetAlert(id) {
      await this.resetUserPassword(id)
        .then(() => {
          this.showChangePasswordSuccess();
        })
        .catch(err => {
          console.error(err);
        });
    },
    showChangePasswordSuccess() {
      this.$vs.notify({
        title: "Success",
        text: "Reset password mail sent to user.",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "success"
      });
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },

    refreshData() {
      this.getAllCountries()
        .then(res => {
          this.countryOptions = this.$store.state.group.countryOptions;
          this.getAllGroups();
        })
        .catch(err => {
          console.error(err);
        });
    },

    viewGroup(id) {
      this.$router.push({ path: "/admin/group/view/" + id });
    },
    getAllGroups(from = null) {
      this.page++;
      if (from) {
        this.page = 1;
      }
      let filterQuery = {
        country:
          this.countryFilter.value == "all" ? "" : this.countryFilter.value,
        state: this.stateFilter.value == "all" ? "" : this.stateFilter.value,
        sortBy: this.sortByFilter.value,
        search: this.searchQuery,
        page: this.page,
        limit: this.limit
      };

      this.$vs.loading();
      this.fetchGroups(filterQuery)
        .then(async res => {
          if (res.status === 200) {
            const a = await res.data.data.docs;
            if (from) {
              this.groups = a;
            } else {
              if (a.length) {
                a.forEach(item => {
                  this.groups.push(item);
                });
              }
            }

            a.length < this.pageSize && (this.loadMore = false);
          }
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.loading.close();
          console.error(err);
        });
    },
    addNewGroup() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    editGroup(data) {
      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },
    deleteData(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.delete(id);
        }
      });
    },
    delete(id) {
      this.$vs.loading();
      this.deleteGroupById(id)
        .then(res => {
          this.getAllGroups();
          this.showMessage("Success", "Group Updated successfully.", "success");
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.loading.close();
          console.error(err);
        });
    },
    acceptDeleteAlert() {},
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    }
  },
  created() {
    this.getAllCountries()
      .then(res => {
        this.countryOptions = this.$store.state.group.countryOptions;
      })
      .catch(err => {
        console.error(err);
      });
  }
};
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

.vs-input--placeholder.normal {
  padding: 0 10px !important;
  top: 10px !important;
}
</style>
